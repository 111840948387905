import { Fab } from 'react-tiny-fab';
import './floatingActionButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { navLinks } from '../Navbar/navData';
import { blurOnMouseUp } from '../../utils/focus';
import { useState } from 'react';

const FloatingActionButton = props => {
  const mainButtonStyles = {
    top: 24,
    left: 24,
  };

  const { location } = props;
  const [hashKey, setHashKey] = useState();

  const isMatch = ({ match, hash = '' }) => {
    if (!match) return false;
    return `${match.url}${hash}` === `${location.pathname}${location.hash}`;
  };

  const handleNavClick = () => {
    setHashKey(Math.random().toString(32).substr(2, 8));
  };

  return (
    <Fab
      mainButtonStyles={mainButtonStyles}
      icon={<FontAwesomeIcon icon={faEllipsisV} />}
      alwaysShowTitle={true}
    >
      {navLinks.map(({ label, pathname, hash }) => (
        <NavLink
          exact
          className="navbar__nav-link"
          activeClassName="navbar__nav-link--active"
          isActive={match => isMatch({ match, hash })}
          onClick={handleNavClick}
          key={label}
          to={{ pathname, hash, state: hashKey }}
          onMouseUp={blurOnMouseUp}
        >
          {label}
        </NavLink>
      ))}
    </Fab>
  );
};

export default FloatingActionButton;
