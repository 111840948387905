import classNames from "classnames";
import { useId } from "hooks";
import "./index.css";

function Monogram({ highlight, className, ...props }) {
  const id = useId();
  const linearId = `linear-${id}`;
  const clipId = `monogram-clip-${id}`;

  return (
    <svg
      aria-hidden
      className={classNames("monogram", className)}
      width="45"
      height="45"
      viewBox="0 0 591.6 591.6"
      {...props}
    >
      <defs>
        <linearGradient id={linearId} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#64bbe8" />
          <stop offset="80%" stopColor="#c5aed3" />
        </linearGradient>
        <clipPath id={clipId}>
          <path
            d="M589.152,3.672C587.112,1.428,584.46,0,581.4,0H10.2C7.14,0,4.284,1.224,2.448,3.672c-2.04,2.244-2.856,5.304-2.244,8.364
            l20.4,122.4c0.816,4.896,5.1,8.568,9.996,8.568h40.8c5.712,0,10.2-4.488,10.2-10.2V81.6h163.2v418.404
            c0.204,6.936,0.408,23.256-4.692,28.56c-0.816,0.816-2.244,1.836-5.508,1.836h-40.8c-5.712,0-10.2,4.488-10.2,10.2v40.8
            c0,5.712,4.488,10.2,10.2,10.2h204c5.712,0,10.2-4.488,10.2-10.2v-40.8c0-5.712-4.488-10.2-10.2-10.2H357
            c-5.916,0-7.956-5.304-9.384-23.46c-0.204-2.652-0.408-5.304-0.816-7.752V81.6H510v51c0,5.712,4.488,10.2,10.2,10.2H561
            c4.896,0,9.18-3.672,9.996-8.568l20.4-122.4C592.008,8.976,591.192,5.916,589.152,3.672z M552.432,122.4H530.4v-51
            c0-5.712-4.488-10.2-10.2-10.2H336.6c-5.712,0-10.2,4.488-10.2,10.2v428.4c0,0.408,0,1.02,0,1.428
            c0.204,2.04,0.408,4.692,0.612,7.344C328.44,523.26,330.48,550.8,357,550.8h30.6v20.4H204v-20.4h30.6
            c7.956,0,14.892-2.652,19.992-7.956C264.996,532.44,265.404,515.1,265.2,499.8V71.4c0-5.712-4.488-10.2-10.2-10.2H71.4
            c-5.712,0-10.2,4.488-10.2,10.2v51H39.168l-16.932-102h547.128L552.432,122.4z"
          />
          <path
            d="M51,39.78h489.6c2.856,0,5.1-2.244,5.1-5.1s-2.244-5.1-5.1-5.1H51c-2.856,0-5.1,2.244-5.1,5.1S48.144,39.78,51,39.78z" />
          <path
            d="M279.48,66.3c-2.856,0-5.1,2.244-5.1,5.1v428.4c0,2.856,2.244,5.1,5.1,5.1s5.1-2.244,5.1-5.1V71.4C284.58,68.544,282.336,66.3,279.48,66.3z"
          />
        </clipPath>
      </defs>
      <rect
        clipPath={`url(#${clipId})`}
        fill={`url(#${linearId})`}
        width="100%"
        height="100%"
      />
      {highlight && (
        <g clipPath={`url(#${clipId})`}>
          <rect className="monogram__highlight" width="100%" height="100%" />
        </g>
      )}
    </svg>
  );
}

export default Monogram;
